import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import ReactDOM from "react-dom";
import Carousel from "react-elastic-carousel";
import '../../App.css';
import {
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2, 
    TextWrapper, 
    TopLine, 
    Subtitle,
    ImgWrap,
    ImgMain,
    Item,
    Itemimg,
    CarouselContainer,
    Span1,
    Span2,
    Span3
} from './InfoElements';


const InfoSection = ({ 
    imeg, 
    id,
    alt,
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    span1,
    span2,
    span3,
    name,

}) => {
    const [toggler, setToggler] = useState(false);

    const breakPoints = [
    { width: 1, itemsToShow: 1, /*itemsToScroll: 2 */ },
        // { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        // { width: 768, itemsToShow: 3 },
        // { width: 1200, itemsToShow: 4 },
    ];

    return (
        <>
            <InfoContainer id= {id}>
                <InfoWrapper>
                    <InfoRow >
                        <Column2>
                            <ImgWrap>
                                <ImgMain src={imeg} alt={alt} />
                            </ImgWrap>
                                <CarouselContainer>
                                    <FsLightbox
                                        toggler={toggler}
                                        sources={[
                                            Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10,
                                        ]}/> 
                                    <Carousel breakPoints={breakPoints}>       
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image1} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)}src={Image2} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image3} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image4} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image5} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image6} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image7} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image8} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image9} alt=''/></Item>
                                        <Item><Itemimg onClick={() => setToggler(!toggler)} src={Image10} alt=''/></Item>
                                    </Carousel>
                                </CarouselContainer>
                        </Column2>
                        <Column1>
                            <TextWrapper>
                                <TopLine>Apie mus</TopLine>
                                <Subtitle>
                                    <Span1><b>{name}</b>{span1}</Span1><br/>
                                    <Span2>{span2}</Span2><br/>
                                    <Span3>{span3}</Span3>
                                </Subtitle>
                            </TextWrapper>
                        </Column1>
                    </InfoRow>
                </InfoWrapper>     
            </InfoContainer>   
        </>
    )
}
const rootElement = document.getElementById("root");
ReactDOM.render(<InfoSection />, rootElement);
export default InfoSection
