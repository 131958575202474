import React from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import background from '../../images/Photo.jpg';
import {
    ContactContainer, 
    ContactWrapper, 
    ContactRow, 
    Column1, 
    Column2, 
    TextWrapper,
    ContactForm,
    H1,
    Form,
    Button,
    ContactFill,
    ContactLabel,
    ContactInput,
    TextArea,
    Conta,
    Span,


} from './ContactElements'

const Contacts = () => {

    const notify = () => toast.success('Žinutė išsiųsta!', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,   
        });

    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_qdwubnd', 'template_njx35l9', e.target, 'user_qgmCZx45HyTAJSRhZ1m0o')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    }

    return (
        <>
            <ContactContainer id='contacts' style={{ backgroundImage: `url(${background})` }}>
                <ContactWrapper>
                    <ContactRow >
                        <Column1>
                            <TextWrapper>
                                <Conta><Span>Kraštovaidzdžio architektė: Viktorija Jonutė</Span></Conta>
                                <Conta><Span>Telefono numeris: +370 69001098</Span></Conta>
                                <Conta><Span>El. paštas: landarchvg@gmail.com</Span></Conta>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ContactForm>
                                <H1>Susisiekite:</H1>
                                <Form className="contact-form" onSubmit={sendEmail}>
                                    <ContactFill>
                                        <ContactLabel htmlFor="name">Pilnas vardas:</ContactLabel>
                                        <ContactInput type="text" id="name" name="name" required />
                                    </ContactFill>
                                    <ContactFill>
                                        <ContactLabel htmlFor="email">Elektroninis paštas:</ContactLabel>
                                        <ContactInput type="email" id="email" name="email" required />
                                    </ContactFill>
                                    <ContactFill>
                                        <ContactLabel htmlFor="name">Tema:</ContactLabel>
                                        <ContactInput type="text" id="name" name="subject" required />
                                    </ContactFill>
                                    <ContactFill>
                                        <ContactLabel htmlFor="message">Jūsų žinutė:</ContactLabel>
                                        <TextArea rows="5" id="message" name="message" required />
                                    </ContactFill>
                                    <Button onClick={notify} type="submit">Siųsti</Button>
                                    <ToastContainer 
                                    position="bottom-center"  
                                            autoClose={3000}
                                            hideProgressBar
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover/>
                                </Form>
                            </ContactForm>
                        </Column2>
                    </ContactRow>
                </ContactWrapper>     
            </ContactContainer> 
        </>
    )
}

export default Contacts
