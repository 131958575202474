import styled from 'styled-components';
import { Link as LinkRouter} from 'react-router-dom';
import { Link as LinkScroll} from 'react-scroll';


export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#101522' : 'transparent')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position:sticky;
    top:0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition:0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display:flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width:100%;
    padding: 0 24px;
    max-width:1100px;
`;

export const NavLogo = styled(LinkRouter)`
    color: #fff;
    justify-self:flex-start;
    cursor: pointer;
    font-size:1.2rem;
    display:flex;
    align-items:center;
    margin-left:15px;
    width: 90px;
    font-weight:bold;
    text-decoration:none;
`;

export const IMG =styled.img`
    width: 80px;
    height: 80px;
    opacity: 1;

`;
export const MobileIcon = styled.div `
    display: none;

    @media screen and (max-width: 820px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size:1.8rem;
        cursor:pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
    display:flex;
    align-items:center;
    list-style: none;
    text-align:center;
    margin-right:-22px;

    @media screen and (max-width: 820px){
        display:none;

    }
`;
export const NavItem= styled.li `
    height:80px;

`;
export const NavLinks = styled(LinkScroll)`
    color: #fff;
    display:flex;
    align-items:center;
    text-decoration:none;
    padding:0 1rem;
    height:100%;
    cursor:pointer;

    &.active{
        border-bottom: 3px solid #B0A8B9;
    }
`;