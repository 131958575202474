// Vilniuas Gurių sodai
import project1 from '../../images/projects/VIlniaus miestas, Gurių sodai/1.jpg'
import project2 from '../../images/projects/VIlniaus miestas, Gurių sodai/2.jpg'
import project3 from '../../images/projects/VIlniaus miestas, Gurių sodai/3.jpg'
import project5 from '../../images/projects/VIlniaus miestas, Gurių sodai/5.jpg'
import project7 from '../../images/projects/VIlniaus miestas, Gurių sodai/7.jpg'
import project9 from '../../images/projects/VIlniaus miestas, Gurių sodai/Vilnius-sklypo projektas.jpg'

// Structum, Kryžių kalnas
import project11 from '../../images/projects/Structum, Kryžių kalnas/1.jpg'
import project12 from '../../images/projects/Structum, Kryžių kalnas/2.jpg'
import project13 from '../../images/projects/Structum, Kryžių kalnas/3.jpg'
import project14 from '../../images/projects/Structum, Kryžių kalnas/4.jpg'
import project15 from '../../images/projects/Structum, Kryžių kalnas/5.jpg'
import project16 from '../../images/projects/Structum, Kryžių kalnas/6.jpg'
import project17 from '../../images/projects/Structum, Kryžių kalnas/7.jpg'
import project18 from '../../images/projects/Structum, Kryžių kalnas/8.jpg'
import project19 from '../../images/projects/Structum, Kryžių kalnas/9.jpg'
import project20 from '../../images/projects/Structum, Kryžių kalnas/10.jpg'


//Marijampolė, skardžio g.
import project24 from '../../images/projects/Marijampole, Skardžio g/1.jpg'
import project25 from '../../images/projects/Marijampole, Skardžio g/2.jpg'
import project26 from '../../images/projects/Marijampole, Skardžio g/3.jpg'
import project27 from '../../images/projects/Marijampole, Skardžio g/4.jpg'
import project28 from '../../images/projects/Marijampole, Skardžio g/5.jpg'
import project29 from '../../images/projects/Marijampole, Skardžio g/6.jpg'
import project30 from '../../images/projects/Marijampole, Skardžio g/7.jpg'
import project31 from '../../images/projects/Marijampole, Skardžio g/8.jpg'
import project32 from '../../images/projects/Marijampole, Skardžio g/9.jpg'
import project33 from '../../images/projects/Marijampole, Skardžio g/10.jpg'
import project34 from '../../images/projects/Marijampole, Skardžio g/11.jpg'


//Klaipėdos raj, Martinų k.

import project65 from '../../images/projects/Klaipėdos raj, Martinų k/1.jpg'
import project66 from '../../images/projects/Klaipėdos raj, Martinų k/2.jpg'
import project67 from '../../images/projects/Klaipėdos raj, Martinų k/3.jpg'
import project68 from '../../images/projects/Klaipėdos raj, Martinų k/4.jpg'
import project69 from '../../images/projects/Klaipėdos raj, Martinų k/5.jpg'
import project70 from '../../images/projects/Klaipėdos raj, Martinų k/6.jpg'
import project71 from '../../images/projects/Klaipėdos raj, Martinų k/7.jpg'
import project73 from '../../images/projects/Klaipėdos raj, Martinų k/9.jpg'
import project74 from '../../images/projects/Klaipėdos raj, Martinų k/10.jpg'
import project75 from '../../images/projects/Klaipėdos raj, Martinų k/11.jpg'
import project76 from '../../images/projects/Klaipėdos raj, Martinų k/12.jpg'
import project77 from '../../images/projects/Klaipėdos raj, Martinų k/13.jpg'
import project78 from '../../images/projects/Klaipėdos raj, Martinų k/14.jpg'
import project79 from '../../images/projects/Klaipėdos raj, Martinų k/15.jpg'
import project80 from '../../images/projects/Klaipėdos raj, Martinų k/16.jpg'
import project81 from '../../images/projects/Klaipėdos raj, Martinų k/17.jpg'
import project82 from '../../images/projects/Klaipėdos raj, Martinų k/18.jpg'
import project83 from '../../images/projects/Klaipėdos raj, Martinų k/19.jpg'
import project84 from '../../images/projects/Klaipėdos raj, Martinų k/koncepcija.jpg'


//Klaipėdos poliklinika

import project87 from '../../images/projects/Klaipėdos poliklinika/1.jpg'
import project88 from '../../images/projects/Klaipėdos poliklinika/2.jpg'
import project89 from '../../images/projects/Klaipėdos poliklinika/3.jpg'
import project90 from '../../images/projects/Klaipėdos poliklinika/4.jpg'
import project91 from '../../images/projects/Klaipėdos poliklinika/5.jpg'
import project92 from '../../images/projects/Klaipėdos poliklinika/6.jpg'
import project93 from '../../images/projects/Klaipėdos poliklinika/7.jpg'

//Klaipėda

import project94 from '../../images/projects/Klaipėda/Koncepcija.jpg'
import project95 from '../../images/projects/Klaipėda/projektas.jpg'
import project96 from '../../images/projects/Klaipėda/reljefo brezinys.jpg'
import project97 from '../../images/projects/Klaipėda/dangų brezinys.jpg'
import project98 from '../../images/projects/Klaipėda/matmenu ir apsvietimas.jpg'


//Botanikos sodas, Klaipėda

import project122 from '../../images/projects/Botanikos sodas, Klaipėda/1.jpg'
import project123 from '../../images/projects/Botanikos sodas, Klaipėda/2.jpg'
import project124 from '../../images/projects/Botanikos sodas, Klaipėda/3.jpg'
import project125 from '../../images/projects/Botanikos sodas, Klaipėda/4.jpg'
import project126 from '../../images/projects/Botanikos sodas, Klaipėda/5.jpg'
import project127 from '../../images/projects/Botanikos sodas, Klaipėda/6.jpg'
import project128 from '../../images/projects/Botanikos sodas, Klaipėda/7.jpg'
import project129 from '../../images/projects/Botanikos sodas, Klaipėda/8.jpg'
import project130 from '../../images/projects/Botanikos sodas, Klaipėda/9.jpg'

//Anyksčių m. Structum V

import project131 from '../../images/projects/Anykščių m, Structum V/1.jpg'
import project132 from '../../images/projects/Anykščių m, Structum V/2.jpg'

//Marijampolė. STRUCTUM VII
import project133 from '../../images/projects/visuomeninis- structum VII/1.jpg'
import project134 from '../../images/projects/visuomeninis- structum VII/2.jpg'
import project135 from '../../images/projects/visuomeninis- structum VII/3.jpg'
import project136 from '../../images/projects/visuomeninis- structum VII/4.jpg'
import project137 from '../../images/projects/visuomeninis- structum VII/5.jpg'
import project138 from '../../images/projects/visuomeninis- structum VII/6.jpg'
import project139 from '../../images/projects/visuomeninis- structum VII/7.jpg'
import project140 from '../../images/projects/visuomeninis- structum VII/8.jpg'
import project141 from '../../images/projects/visuomeninis- structum VII/9.jpg'
import project142 from '../../images/projects/visuomeninis- structum VII/10.jpg'
import project143 from '../../images/projects/visuomeninis- structum VII/11.jpg'
import project144 from '../../images/projects/visuomeninis- structum VII/12.jpg'
import project145 from '../../images/projects/visuomeninis- structum VII/13.jpg'
import project146 from '../../images/projects/visuomeninis- structum VII/14.jpg'
import project147 from '../../images/projects/visuomeninis- structum VII/15.jpg'
import project148 from '../../images/projects/visuomeninis- structum VII/16.jpg'
import project149 from '../../images/projects/visuomeninis- structum VII/17.jpg'

//Šilutė
import project151 from '../../images/projects/Šilutė/107.jpg'
import project152 from '../../images/projects/Šilutė/12.jpg'
import project154 from '../../images/projects/Šilutė/132.jpg'
import project155 from '../../images/projects/Šilutė/133.jpg'
import project156 from '../../images/projects/Šilutė/28.jpg'
import project160 from '../../images/projects/Šilutė/44.jpg'
import project161 from '../../images/projects/Šilutė/52.jpg'
import project162 from '../../images/projects/Šilutė/7.jpg'
import project163 from '../../images/projects/Šilutė/72.jpg'
import project164 from '../../images/projects/Šilutė/75.jpg'
import project165 from '../../images/projects/Šilutė/78.jpg'
import project166 from '../../images/projects/Šilutė/82.jpg'
import project167 from '../../images/projects/Šilutė/90.jpg'

//Šilutė
import project169 from '../../images/projects/Projektas Šilutė/2.jpg'
import project168 from '../../images/projects/Projektas Šilutė/1.jpg'
import project170 from '../../images/projects/Projektas Šilutė/3.jpg'
import project171 from '../../images/projects/Projektas Šilutė/4.jpg'
import project172 from '../../images/projects/Projektas Šilutė/5.jpg'
import project173 from '../../images/projects/Projektas Šilutė/6.jpg'
import project174 from '../../images/projects/Projektas Šilutė/7.jpg'
import project175 from '../../images/projects/Projektas Šilutė/8.jpg'
import project176 from '../../images/projects/Projektas Šilutė/9.jpg'
import project177 from '../../images/projects/Projektas Šilutė/10.jpg'
import project178 from '../../images/projects/Projektas Šilutė/11.jpg'
import project179 from '../../images/projects/Projektas Šilutė/12.jpg'
import project180 from '../../images/projects/Projektas Šilutė/13.jpg'
import project181 from '../../images/projects/Projektas Šilutė/14.jpg'
import project182 from '../../images/projects/Projektas Šilutė/15.jpg'
import project183 from '../../images/projects/Projektas Šilutė/16.jpg'
import project184 from '../../images/projects/Projektas Šilutė/17.jpg'
import project185 from '../../images/projects/Projektas Šilutė/18.jpg'
import project186 from '../../images/projects/Projektas Šilutė/19.jpg'
import project187 from '../../images/projects/Projektas Šilutė/20.jpg'
import project188 from '../../images/projects/Projektas Šilutė/21.jpg'

//Priekulė

import project189 from '../../images/projects/Priekulė/10.jpg'
import project193 from '../../images/projects/Priekulė/13.jpg'
import project194 from '../../images/projects/Priekulė/14.jpg'
import project195 from '../../images/projects/Priekulė/15.jpg'
import project196 from '../../images/projects/Priekulė/16.jpg'
import project197 from '../../images/projects/Priekulė/17.jpg'
import project198 from '../../images/projects/Priekulė/18.jpg'
import project199 from '../../images/projects/Priekulė/19.jpg'
import project200 from '../../images/projects/Priekulė/2.jpg'
import project201 from '../../images/projects/Priekulė/20.jpg'
import project204 from '../../images/projects/Priekulė/4.jpg'
import project206 from '../../images/projects/Priekulė/6.jpg'
import project208 from '../../images/projects/Priekulė/8.jpg'
import project209 from '../../images/projects/Priekulė/9.jpg'


//Klaipėda, modernus projektas
import project211 from '../../images/projects/Klaipėda.Modernus projektas/2.jpg'
import project212 from '../../images/projects/Klaipėda.Modernus projektas/3.jpg'
import project213 from '../../images/projects/Klaipėda.Modernus projektas/4.jpg'
import project214 from '../../images/projects/Klaipėda.Modernus projektas/5.jpg'
import project215 from '../../images/projects/Klaipėda.Modernus projektas/6.jpg'
import project216 from '../../images/projects/Klaipėda.Modernus projektas/7.jpg'
import project219 from '../../images/projects/Klaipėda.Modernus projektas/10.jpg'
import project220 from '../../images/projects/Klaipėda.Modernus projektas/11.jpg'
import project221 from '../../images/projects/Klaipėda.Modernus projektas/12.jpg'

//VILNIUS
import project222 from '../../images/projects/Vilnius/3.jpg'
import project223 from '../../images/projects/Vilnius/4.jpg'
import project224 from '../../images/projects/Vilnius/6.jpg'
import project225 from '../../images/projects/Vilnius/7.jpg'
import project226 from '../../images/projects/Vilnius/9.jpg'
import project227 from '../../images/projects/Vilnius/11.jpg'
import project228 from '../../images/projects/Vilnius/13.jpg'
import project229 from '../../images/projects/Vilnius/14.jpg'
import project230 from '../../images/projects/Vilnius/15.jpg'
import project232 from '../../images/projects/Vilnius/19.jpg'
import project233 from '../../images/projects/Vilnius/20.jpg'
import project234 from '../../images/projects/Vilnius/21.jpg'
import project235 from '../../images/projects/Vilnius/22.jpg'

//Šilute2

import sil21 from '../../images/projects/Šilute2/1.jpg'
import sil22 from '../../images/projects/Šilute2/2.jpg'
import sil23 from '../../images/projects/Šilute2/3.jpg'
import sil24 from '../../images/projects/Šilute2/4.jpg'
import sil25 from '../../images/projects/Šilute2/5.jpg'
import sil26 from '../../images/projects/Šilute2/6.jpg'

//Šilutė1

import sil11 from '../../images/projects/Šilutė1/2.jpg'
import sil12 from '../../images/projects/Šilutė1/6.jpg'
import sil13 from '../../images/projects/Šilutė1/7.jpg'
import sil15 from '../../images/projects/Šilutė1/11.jpg'
import sil18 from '../../images/projects/Šilutė1/33.jpg'
import sil19 from '../../images/projects/Šilutė1/34.jpg'
import sil110 from '../../images/projects/Šilutė1/36.jpg'
import sil111 from '../../images/projects/Šilutė1/37.jpg'
import sil112 from '../../images/projects/Šilutė1/42.jpg'
import sil113 from '../../images/projects/Šilutė1/44.jpg'
import sil114 from '../../images/projects/Šilutė1/46.jpg'
import sil115 from '../../images/projects/Šilutė1/50.jpg'
import sil117 from '../../images/projects/Šilutė1/55.jpg'
import sil118 from '../../images/projects/Šilutė1/57.jpg'
import sil120 from '../../images/projects/Šilutė1/62.jpg'
import sil121 from '../../images/projects/Šilutė1/68.jpg'
import sil122 from '../../images/projects/Šilutė1/69.jpg'


import sil123 from '../../images/projects/Šilutė1/Naktinės/6.jpg'
import sil124 from '../../images/projects/Šilutė1/Naktinės/10.jpg'
import sil125 from '../../images/projects/Šilutė1/Naktinės/17.jpg'
import sil126 from '../../images/projects/Šilutė1/Naktinės/30.jpg'
import sil129 from '../../images/projects/Šilutė1/Naktinės/33.jpg'

//Kretingos raj. VYDMANTAI

import vyd1 from '../../images/projects/Kretingos raj. Vydmantai/3.jpg'
import vyd2 from '../../images/projects/Kretingos raj. Vydmantai/5.jpg'
import vyd3 from '../../images/projects/Kretingos raj. Vydmantai/6.jpg'
import vyd4 from '../../images/projects/Kretingos raj. Vydmantai/8.jpg'
import vyd5 from '../../images/projects/Kretingos raj. Vydmantai/9.jpg'
import vyd6 from '../../images/projects/Kretingos raj. Vydmantai/11.jpg'
import vyd7 from '../../images/projects/Kretingos raj. Vydmantai/12.jpg'
import vyd8 from '../../images/projects/Kretingos raj. Vydmantai/14.jpg'
import vyd9 from '../../images/projects/Kretingos raj. Vydmantai/15.jpg'
import vyd10 from '../../images/projects/Kretingos raj. Vydmantai/18.jpg'
import vyd11 from '../../images/projects/Kretingos raj. Vydmantai/20.jpg'
import vyd12 from '../../images/projects/Kretingos raj. Vydmantai/21.jpg'
import vyd13 from '../../images/projects/Kretingos raj. Vydmantai/22.jpg'
import vyd14 from '../../images/projects/Kretingos raj. Vydmantai/24.jpg'
import vyd15 from '../../images/projects/Kretingos raj. Vydmantai/26.jpg'
import vyd16 from '../../images/projects/Kretingos raj. Vydmantai/27.jpg'
import vyd17 from '../../images/projects/Kretingos raj. Vydmantai/29.jpg'
import vyd18 from '../../images/projects/Kretingos raj. Vydmantai/30.jpg'
import vyd19 from '../../images/projects/Kretingos raj. Vydmantai/31.jpg'
import vyd20 from '../../images/projects/Kretingos raj. Vydmantai/33.jpg'
import vyd21 from '../../images/projects/Kretingos raj. Vydmantai/34.jpg'
import vyd22 from '../../images/projects/Kretingos raj. Vydmantai/35.jpg'
import vyd23 from '../../images/projects/Kretingos raj. Vydmantai/36.jpg'
import vyd24 from '../../images/projects/Kretingos raj. Vydmantai/37.jpg'
import vyd25 from '../../images/projects/Kretingos raj. Vydmantai/38.jpg'
import vyd26 from '../../images/projects/Kretingos raj. Vydmantai/39.jpg'
import vyd27 from '../../images/projects/Kretingos raj. Vydmantai/41.jpg'
import vyd28 from '../../images/projects/Kretingos raj. Vydmantai/45.jpg'

import vyd29 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/3.jpg'
import vyd30 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/4.jpg'
import vyd31 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/5.jpg'
import vyd32 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/6.jpg'
import vyd33 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/10.jpg'
import vyd34 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/11.jpg'
import vyd35 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/14.jpg'
import vyd36 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/20.jpg'
import vyd37 from '../../images/projects/Kretingos raj. Vydmantai/Naktinės naujos/21.jpg'

//Klaipėdos raj, Jakai

import jak1 from '../../images/projects/Klaipėdos raj. Jakų k/1.jpg'
import jak2 from '../../images/projects/Klaipėdos raj. Jakų k/2.jpg'
import jak3 from '../../images/projects/Klaipėdos raj. Jakų k/3.jpg'
import jak4 from '../../images/projects/Klaipėdos raj. Jakų k/4.jpg'
import jak5 from '../../images/projects/Klaipėdos raj. Jakų k/5.jpg'
import jak6 from '../../images/projects/Klaipėdos raj. Jakų k/6.jpg'
import jak7 from '../../images/projects/Klaipėdos raj. Jakų k/7.jpg'
import jak8 from '../../images/projects/Klaipėdos raj. Jakų k/8.jpg'
import jak9 from '../../images/projects/Klaipėdos raj. Jakų k/9.jpg'

// VDG KLAIPEDA

import vdg1 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/1.jpg'
import vdg2 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/4.jpg'
import vdg3 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/5.jpg'
import vdg4 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/6.jpg'
import vdg5 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/8.jpg'
import vdg6 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/9.jpg'
import vdg7 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/10.jpg'
import vdg8 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/11.jpg'
import vdg9 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/13.jpg'
import vdg10 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/14.jpg'
import vdg11 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/15.jpg'
import vdg12 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/16.jpg'
import vdg13 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/17.jpg'
import vdg14 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/18.jpg'
import vdg15 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/19.jpg'
import vdg16 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/20.jpg'
import vdg17 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/21.jpg'
import vdg18 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/22.jpg'
import vdg19 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/23.jpg'
import vdg20 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/24.jpg'
import vdg21 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/25.jpg'
import vdg22 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/26.jpg'
import vdg23 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/27.jpg'
import vdg25 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/29.jpg'
import vdg26 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/30.jpg'
import vdg27 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/34.jpg'
import vdg28 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/35.jpg'
import vdg29 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/37.jpg'
import vdg30 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/38.jpg'
import vdg31 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/39.jpg'
import vdg33 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/41.jpg'
import vdg34 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/42.jpg'
import vdg35 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/43.jpg'
import vdg36 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/44.jpg'
import vdg37 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/45.jpg'
import vdg38 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/46.jpg'
import vdg39 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/47.jpg'


import vdg40 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/Naktinės/3.jpg'
import vdg41 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/Naktinės/8.jpg'
import vdg42 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/Naktinės/9.jpg'
import vdg43 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/Naktinės/11.jpg'
import vdg44 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/Naktinės/12.jpg'
import vdg45 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/Naktinės/15.jpg'
import vdg46 from '../../images/projects/Klaipėdos Vytauto Didžiojo gimnazija/Naktinės/16.jpg'


///Klaipėdos raj. Grabiai


import gr1 from '../../images/projects/Klaipėdos raj. Grabiai/1.jpg'
import gr2 from '../../images/projects/Klaipėdos raj. Grabiai/2.jpg'
import gr3 from '../../images/projects/Klaipėdos raj. Grabiai/3.jpg'
import gr4 from '../../images/projects/Klaipėdos raj. Grabiai/4.jpg'
import gr5 from '../../images/projects/Klaipėdos raj. Grabiai/5.jpg'
import gr6 from '../../images/projects/Klaipėdos raj. Grabiai/6.jpg'
import gr7 from '../../images/projects/Klaipėdos raj. Grabiai/7.jpg'
import gr8 from '../../images/projects/Klaipėdos raj. Grabiai/8.jpg'
import gr9 from '../../images/projects/Klaipėdos raj. Grabiai/9.jpg'
import gr10 from '../../images/projects/Klaipėdos raj. Grabiai/10.jpg'
import gr11 from '../../images/projects/Klaipėdos raj. Grabiai/11.jpg'
import gr12 from '../../images/projects/Klaipėdos raj. Grabiai/12.jpg'
import gr13 from '../../images/projects/Klaipėdos raj. Grabiai/13.jpg'
import gr14 from '../../images/projects/Klaipėdos raj. Grabiai/14.jpg'
import gr15 from '../../images/projects/Klaipėdos raj. Grabiai/15.jpg'
import gr16 from '../../images/projects/Klaipėdos raj. Grabiai/16.jpg'
import gr17 from '../../images/projects/Klaipėdos raj. Grabiai/17.jpg'
import gr18 from '../../images/projects/Klaipėdos raj. Grabiai/19.jpg'
import gr19 from '../../images/projects/Klaipėdos raj. Grabiai/20.jpg'
import gr20 from '../../images/projects/Klaipėdos raj. Grabiai/21.jpg'
import gr21 from '../../images/projects/Klaipėdos raj. Grabiai/23.jpg'
import gr22 from '../../images/projects/Klaipėdos raj. Grabiai/24.jpg'
import gr23 from '../../images/projects/Klaipėdos raj. Grabiai/26.jpg'
import gr24 from '../../images/projects/Klaipėdos raj. Grabiai/27.jpg'
import gr25 from '../../images/projects/Klaipėdos raj. Grabiai/28.jpg'
import gr26 from '../../images/projects/Klaipėdos raj. Grabiai/29.jpg'
import gr27 from '../../images/projects/Klaipėdos raj. Grabiai/30.jpg'
import gr28 from '../../images/projects/Klaipėdos raj. Grabiai/32.jpg'
import gr29 from '../../images/projects/Klaipėdos raj. Grabiai/33.jpg'

import gr30 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/1.jpg'
import gr31 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/4.jpg'
import gr32 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/5.jpg'
import gr33 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/6.jpg'
import gr34 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/7.jpg'
import gr35 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/8.jpg'
import gr36 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/9.jpg'
import gr37 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/11.jpg'
import gr38 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/12.jpg'
import gr39 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/13.jpg'
import gr40 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/17.jpg'
import gr41 from '../../images/projects/Klaipėdos raj. Grabiai/Naktinės/18.jpg'



const data = [
    {   
        id:20,
        image: gr15,
        title: 'Klaipėdos raj. Grabiai',
        category: 'Privatūs',
        // description: 'ALTUIALISKLF',
        projectimages:[gr1,gr2,gr3,gr4,gr5,gr6,gr7,gr8,gr9,gr10,gr11,gr12,gr13,gr14,gr15,gr16,gr17,gr18,gr19,gr20,gr21,gr22,gr23,gr24,gr25,gr26,gr27,gr28,gr29,gr30,gr31,gr32,gr33,gr34,gr35,gr36,gr37,gr38,gr39,gr40,gr41]
    },
    {   
        id:19,
        image: vdg12,
        title: 'Vytauto Didžiojo gimnazija, Klaipėda',
        category: 'Visuomeniniai',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[vdg1 ,vdg2 ,vdg3 ,vdg4 ,vdg5 ,vdg6 ,vdg7 ,vdg8 ,vdg9 ,vdg10,vdg11,vdg12,vdg13,vdg14,vdg15,vdg16,vdg17,vdg18,vdg19,vdg20,vdg21,vdg22,vdg23,vdg25,vdg26,vdg27,vdg28,vdg29,vdg30,vdg31,vdg33,vdg34,vdg35,vdg36,vdg37,vdg38,vdg39,vdg40,vdg41,vdg42,vdg43,vdg44,vdg45,vdg46]
    },
    {   
        id:18,
        image: jak7,
        title: 'Klaipėdos raj. Jakų k.',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[jak1,jak2,jak3,jak4,jak5,jak6,jak7,jak8,jak9]
    },
    {
        id:17,
        image: vyd27,
        title: 'Kretingos raj. Vydmantai',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[vyd1,vyd2,vyd3,vyd4,vyd5,vyd6,vyd7,vyd8,vyd9,vyd10,vyd11,vyd12,vyd13,vyd14,vyd15,vyd16,vyd17,vyd18,vyd19,vyd20,vyd21,vyd22,vyd23,vyd24,vyd25,vyd26,vyd27,vyd28,vyd29,vyd30,vyd31,vyd32,vyd33,vyd34,vyd35,vyd36,vyd37]
    },
    {
        id:16,
        image: sil15,
        title: 'Šilutė',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[sil11 ,sil12 ,sil13,sil15,sil18 ,sil19 ,sil110,sil111,sil112,sil113,sil114,sil115,sil117,sil118,sil120,sil121,sil122,sil123,sil124,sil125,sil126,sil129]
    },
    {
        id:15,
        image: sil25,
        title: 'Šilutė',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[sil21,sil22,sil23,sil24,sil25,sil26]
    },
    {
        id:14,
        image: project228,
        title: 'Vilnius',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[project222,project223,project224,project225,project226,project227,project228,project229,project230,project232,project233,project234,project235]
    },
    {
        id:13,
        image: project215,
        title: 'Klaipėda',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[project211,project212,project213,project214,project215,project216,project219,project220,project221,]
    },
    {
        id:12,
        image: project189,
        title: 'Priekulė',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[project189,project193,project194,project195,project196,project197,project198,project199,project200,project201,project204,project206,project208,project209]
    },
    {
        id:11,
        image: project171,
        title: 'Šilutė',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages:[project168,project169,project170,project171,project172,project173,project174,project175,project176,project177,project178,project179,project180,project181,project182,project183,project184,project185,project186,project187,project188,]
    },
    {
        id:10,
        image: project156,
        title: 'Šilutė',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project151,project152,project154,project155,project156,project160,project161,project162,project163,project164,project165,project166,project167,]
    },
    {
        id:9,
        image: project141,
        title: 'Marijampolės m. Structum VII',
        category: 'Visuomeniniai',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project133,project134,project135,project136,project137,project138,project139,project140,project141,project142,project143,project144,project145,project146,project147,project148,project149,]
    },
    {
        id:8,
        image: project132,
        title: 'Anykščių m, Structum V',
        category: 'Visuomeniniai',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project131,project132]
    },
    {
        id:7,
        image: project129,
        title: 'Botanikos sodas, Klaipėda',
        category: 'Visuomeniniai',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project122,project123,project124,project125,project126,project127,project128,project129,project130]
    },
    {
        id:6,
        image: project94,
        title: 'Klaipėda',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project94,project95,project96,project97,project98]
    },
    {
        id:5,
        image: project88,
        title: 'Klaipėdos poliklinika-konkursas',
        category: 'Visuomeniniai',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project87,project88,project89,project90,project91,project92,project93]
    },
    {
        id:4,
        image: project79,
        title: 'Klaipėdos raj, Martinų k.',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project65,project66,project67,project68,project69,project70,project71,project73,project74,project75,project76,project77,project78,project79,project80,project81,project82,project83,project84]
    },
    {
        id:3,
        image: project26,
        title: 'Marijampolė, Skardžio g.',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project24,project25,project26,project27,project28,project29,project30,project31,project32,project33,project34]
    },
    
    {
        id:2,
        image: project14,
        title: 'Kryžių kalnas, Structum konkursas',
        category: 'Visuomeniniai',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project11,project12,project13,project14,project15,project16,project17,project18,project19,project20]
    },
    {
        id:1,
        image: project2,
        title: 'Vilniaus m., Gurių sodai.',
        category: 'Privatūs',
        // description: 'ČIA KAŽKOKS TEKSASsdfsfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdfsdf',
        projectimages: [project1,project2,project3,project5,project7,project9]
    },
];

export default data;