import styled from 'styled-components';

import backgroundphoto1 from '../../images/background1.jpg'


export const ServicesContainer = styled.div`

    background-image: url(${backgroundphoto1});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;


    @media screen and (max-width: 768px){
        padding: 50px 0;

    }
`;

export const ServicesWrapper = styled.div`
    display: grid;
    /* height: 860px; */
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 768px){
        /* height: 1400px; */
    }
    @media screen and (max-width: 480px) {
        /* height: 1100px; */
    }
`;

export const ServicesRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`
export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 30px  15px 0;
    grid-area: col1;
`;
export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;

`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;

    @media screen and (max-width: 768px){
        margin-left: 80px;
    }
    @media screen and (max-width: 640px){
        margin-left: 50px;
    }
    @media screen and (max-width: 480px){
        margin-left: 10px;
    }
`;

export const TopLine = styled.div`
    color: #fff;
    line-height: 16px;
    letter-spacing: 1.5px;
    margin-bottom: 16px;
    
    
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 600;
    color: black;
    margin-left: 140px;

    @media screen and (max-width: 900px){
        font-size: 23px;
    }
    @media screen and (max-width: 768px){
        font-size: 20px;
        margin-left:130px;
    }
    @media screen and (max-width: 480px){
        font-size: 18px;
    }
    @media screen and (max-width: 420px){
        font-size: 16px;
        margin-left: 90px;
    }
    @media screen and (max-width: 320px){
        font-size: 16px;
        margin-left: 50px;
    }
`;

export const Subtitle= styled.div`
    max-width: 460px;
    display: grid;
    margin-bottom: 35px;
    font-size: 16px;
    color: black;
    height: 100%;
    text-align: justify;
    word-spacing: normal;
    text-indent:15px;

    @media screen and (max-width: 900px){
        font-size: 15px;
    }
    @media screen and (max-width: 768px){
        font-size: 14px;
    }
    @media screen and (max-width: 480px){
        font-size: 13px;
    }
    @media screen and (max-width: 420px){
        font-size: 12px;
    }
`;
export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
        margin-left: 150px;
    }
    
    @media screen and (max-width: 640px) {
        margin-left: 100px;
    }
    @media screen and (max-width: 480px) {
        margin-left: 50px;
    }
    
`
export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0; 
    align-items:center;
    opacity: 0.5;
    
    @media screen and (max-width: 768px) {
        width: 80%;
    }
    
`;
export const Span = styled.div`
    margin-bottom: 15px;
    font-size: 15px;
    /* color: #C3C2C4; */
    color: black;

    @media screen and (max-width: 900px){
        font-size: 14px;
    }
    @media screen and (max-width: 768px){
        font-size: 13px;
    }
    @media screen and (max-width: 480px){
        font-size: 12px;
    }
    @media screen and (max-width: 420px){
        font-size: 11px;
    }
`;
export const Spantitle = styled.div`
    margin-bottom: 15px;
    font-size: 16px;
    color: black;
    font-weight:700;
    text-decoration: underline;

    @media screen and (max-width: 900px){
        font-size: 15px;
    }
    @media screen and (max-width: 768px){
        font-size: 14px;
    }
    @media screen and (max-width: 480px){
        font-size: 13px;
    }
    @media screen and (max-width: 420px){
        font-size: 12px;
    }
`;
export const ZoomBtn = styled.div`
    font-size: 120px;
    position: absolute;
    object-fit: contain;
    color: #fff;
    z-index:1;
    cursor: pointer;

    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    &:hover{
        font-size:150px;
        transition: .4s;
        
    }
    
    @media screen and (max-width: 900px){
        font-size: 100px;
    }
    @media screen and (max-width: 768px){
        font-size: 87px;
    }
    @media screen and (max-width: 480px){
        font-size: 60px;
    }
    @media screen and (max-width: 420px){
        font-size: 40px;
    }
    @media screen and (max-width: 320px){
        font-size: 20px;
    }
`;