import React from 'react';
import { render } from 'react-dom';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './slider-animations.css';
import './styles.css';
import Icon1 from '../../images/public-1.jpg'
import Icon2 from '../../images/public-2.jpg'
import Icon3 from '../../images/public-3.jpg'
import Icon4 from '../../images/public-4.jpg'
import Icon5 from '../../images/public-5.jpg'
import Icon6 from '../../images/public-6.jpg'
import Icon7 from '../../images/public-7.jpg'

const content = [
	{
		title: ' Structum: „IŠMANUSIS MIESTAS VII“',
		description:
		'Ateities miestas – praeities ir dabarties architektūrinė sintezė, sujungianti mokslą, gamtą, tvarią aplinką...',
		button: [<a onClick={() => {window.open('https://structum.lt/miesto-dalyvis/viktorija-gricenaite-vaida-adomaitiene-andrius-bycenkovas-inga-sakauskaite/?fbclid=IwAR1LfwDLEcL9CVhPN_V9Rjuj8nr9lW_OfQ-OT3PzjjxxPvUAdCSffWtL2kc', '_blank',); return true;}}>Plačiau skaitykite</a>],
		image: [Icon4],
	},
	{
		title: 'Unikalus sveikatingumo sodas Lietuvoje',
		description: 'Pristatome unikalų sveikatingumo sodą – Lina Liubertaitė kalbina kraštovaizdžio architektę Viktoriją Gričenaitę. Jos sukurtas projektas, jau pradėtas įgyvendinti Klaipėdos botanikos sode, yra analogų.....',
		button: [<a  onClick={() => {window.open('https://www.ziniuradijas.lt/laidos/augalu-entuaziastai/svarbus-darbai-kieme-esant-tokiam-sniegui-unikalus-sveikatingumo-sodas-lietuvoje?video=1&fbclid=IwAR2f1hRScMXK2CAU2eJZusMWwCP1p1gGWbPpuEix5dVy2pcn5zDqXsaqsVA', '_blank',); return true;}}>Plačiau skaitykite</a>],
		image: [Icon1],
	},
	{
        title: 'Šioje futuristinėje ligoninėje gamta - vienas iš gydymo būdų',
		description:
		'Pirmas dalykas, kuris ateina į galvą, kai pagalvojame apie ligonines greičiausiai nebus žydintys sodai ir žaluma, tačiau Khoo Teck Puat ligoninė Singapūre keičia šį požiūrį.....',
        button: [<a onClick={() => {window.open('https://minfo.lt/sveikatingumas/straipsnis/sioje-futuristineje-ligonineje-gamta-vienas-is-gydymo-budu?fbclid=IwAR0qCnnZiYyKlzU396sz0HKqyK_K_xo7rEvrUruw4X-LaRb1fESiAHtzXHk', '_blank',); return true;}}>Plačiau skaitykite</a>],
		image: [Icon2],
	},
	{
        title: 'Klaipėdoje įgyvendinamas magistrantės darbas taps unikaliu visame pasaulyje',
		description:
		'Anyžinis lofantas, katžolė, vaistinė magnolija, net 17 rūšių mėtų – šiuos ir kitus prieskoninius bei arbatoms naudojamus augalus...',
        button: [<a onClick={() => {window.open('https://www.15min.lt/naujiena/aktualu/lietuva/klaipedoje-igyvendinamas-magistrantes-darbas-taps-unikaliu-visame-pasaulyje-56-1201740?fbclid=IwAR0XfiTLnr6wT9a_4jCpAPF25w41SQPgsQm21YaRJZ532RVegt-UGthD-l8#_', '_blank',); return true;}}>Plačiau skaitykite</a>],
		image: [Icon3],
	},
	{
        title: 'KLAIPĖDOS BOTANIKOS SODAS ATSINAUJINA: AUGALAI PAGAL POJŪČIUS',
		description:
		'Klaipėdos botanikos sodas išnyra iš džinsų, šoka į raštuotą suknelę...',
        button: [<a onClick={() => {window.open('https://geltonaskarutis.lt/klaipedos-botanikos-sodas-atsinaujina-augalai-pagal-pojucius/?fbclid=IwAR1oZX3EagTbSTz_nCwl_o19Z6wQ7pk-eFL9vpbwwALXIy8iOSgS0mH5OTo', '_blank',); return true;}}>Plačiau skaitykite</a>],
		image: [Icon5],
	},
	{
        title: 'JŪRININKŲ POLIKLINIKA KLAIPĖDOJE: Galutinė konkurso prizinių vietų eilė',
		description:
		'Klaipėdos miesto savivaldybės administracija kartu su Lietuvos architektų sąjungos Klaipėdos apskrities organizacija surengė...',
        button: [<a onClick={() => {window.open('https://pilotas.lt/2019/03/25/architektura/jurininku-poliklinika-klaipedoje-galutine-konkurso-priziniu-vietu-eile/?fbclid=IwAR3oY2NI1dts5Aozvs3OKU4xz9cjZXtWQBTzrVNmUZyB9AGu2wo7wnUVQFU', '_blank',); return true;}}>Plačiau skaitykite</a>],
		image: [Icon7],
	},
	{
        title: 'IŠ STRUCTUM KONKURSO 2 PRIZUS PARSIVEŽUSI KU KOMANDA',
		description:
		'2-ąją vietą konkurse KU komanda laimėjo su projektu Anykščių miesto Troškūnų g. teritorijos koncepcija „Sveikatingumo sodai“.',
        button: [<a onClick={() => {window.open('https://www.ku.lt/blog/is-structum-konkurso-2-prizus-parsivezusi-ku-komanda-pasaulyje-nera-musu-idejos-analogu/?fbclid=IwAR04G_X853ofAu9KldE7hD3ArRAVF52oI-G0B2_vSSO7uxK0fy5b-skO5DU', '_blank',); return true;}}>Plačiau skaitykite</a>],
		image: [Icon6],
	}
];

const Publications = () => {

    return (
        <div id="publications" className="slider-container">
            <Slider className="slider-wrapper" touchDisabled={true}>
				{content.map((item, index) => (
					<div
						key={index}
						className="slider-content"
						style={{ background: `url('${item.image}')  no-repeat center center` }}
					>
						<div className="inner">
							<h1>{item.title}</h1>
							<p>{item.description}</p>
							<button >{item.button}</button>
						</div>
					</div>
				))}
		</Slider>
        </div>
    )
};
render(<Publications />, document.getElementById('root'));
export default Publications;