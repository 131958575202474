export const homeObjOne = {
    id: 'about',
    imeg: require('../../images/asmenine1.jpg'),
    Image1: require('../../images/diplomas-1.jpg'),
    Image2: require('../../images/diplomas-2.jpg'),
    Image3: require('../../images/diplomas-3.jpg'),
    Image4: require('../../images/diplomas-4.jpg'),
    Image5: require('../../images/diplomas-5.jpg'),
    Image6: require('../../images/diplomas-6.jpg'),
    Image7: require('../../images/diplomas-7.jpg'),
    Image8: require('../../images/miestas-1.jpg'),
    Image9: require('../../images/miestas-2.jpg'),
    Image10: require('../../images/miestas-3.jpg'),
    name: '“V&G Kraštovaizdžio architektūros studija” ',
    span1: 'įkvėpta šiuolaikinės kartos, ieškanti įdomių idėjų, netradicinių sprendimų, kurianti aplinką, tarnaujančią žmogui. Ypatingas dėmesys skiriamas funkciškai naudingam aplinkos projektavimui. Kiekvienos aplinkos stilius pritaikomas pagal vietovę, pastato architekūros stilių.',
    span2: 'Pagrindiniai principai: tvarumas, ergonomiškumas, šiuolaikiškumas, naujausios augalų tendencijos, išskirtinės idėjos, aplinkos prisitaikymas prie esamų šeimininkų poreikių.',
    span3: '“V&G Kraštovaizdžio architektūros studija” įkūrėja ir kraštovaizdžio architektė Viktorija Gričenaitė. Dirbanti su privačiomis ir viešomis erdvėmis. Vienas iš įdomiausių ir išskirtinių projektų – „Sveikatingumo sodai“. Tai analogų neturintis projektas, kuris buvo paremtas sukurta metodika - kraštovaizdžio psichoemociniais pojūčiais, per augalų spalvas, kvapus ir garsus. Ši koncepcija buvo pritaikyta ir įgyvendinta Kaipėdos universiteto botanikos sode.',
};
