import styled from 'styled-components';
import backgroundphoto from '../../images/background.jpg'

export const InfoContainer = styled.div`

    background-image: url(${backgroundphoto});
    

    @media screen and (max-width: 768px){
        /* padding: 100px 0; */
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 98px 24px;
    justify-content: center;
    

    @media screen and (max-width: 768px) {
        padding: 0;
    }
    @media screen and (max-width: 480px) {
        padding: 0 24px;
    }
    button.rec-dot{
        box-shadow: 0 0 1px 3px  #C3C2C4;
    }
    .hLZZgJ {
        background-color: #C3C2C4;
    }
    .idNeOo {
        background-color: #C3C2C4;

    }
    
    button.rec-arrow:hover, button.rec-arrow:active, button.rec-arrow:focus  {
        background-color:#C3C2C4;
        background: #C3C2C4;
        cursor: pointer;
        box-shadow: 0 0 11px white;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 25px;
    padding: 0 20px;
    grid-area: col2;
`;
export const Column2 = styled.div`
    margin-bottom: 25px;
    padding: 0 20px;
    grid-area: col1;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 25px;
    padding-bottom: 60px;
    justify-content:center;
   
    @media screen and (max-width: 768px) {
        /* margin-left: 100px; */
    }
    @media screen and (max-width: 640px) {
        margin-left: 50px;
    }
    @media screen and (max-width: 540px) {
        margin-left: 0;
    }
    @media screen and (max-width: 460px) {
        margin-left: 0;
    }
`;

export const TopLine = styled.p`
    font-size: 35px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 90px;
    margin-left: 120px;
    color: #4B4453;

    @media screen and (max-width: 900px) {
        margin-left: 100px;
        margin-bottom: 50px;
        font-size: 30px;

    }
    @media screen and (max-width: 768px) {
        margin-left: 150px;
        margin-bottom: 50px;
        font-size: 30px;

    }
    @media screen and (max-width: 640px) {
        margin-left: 150px;
        margin-bottom: 50px;
        font-size: 20px;

    }
    
    @media screen and (max-width: 480px) {
        margin-left: 80px;
        font-size: 30px;

    }
    @media screen and (max-width: 320px) {
        font-size: 20px;
        margin-left: 35px;

    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #010606;

    @media screen and(max-width: 480px){
        font-size: 32px;
    }
`;

export const Subtitle= styled.div`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 15px;
    line-height: 20px;
    color: black;
    font-weight:600;
    text-align: justify;
    text-indent:25px;

    /* @media screen and (max-width: 768px){

        margin-left: 20px;
    } */

`;


export const ImgWrap = styled.div`
    max-width: 768px;
    height: 100%;
`;

export const ImgMain = styled.img`
    width: 100%;
    margin: 15px 0 10px 0;
    padding-right: 0; 
`;

export const CarouselContainer = styled.div`
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100vh;
`;
export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
  /* background-color: #00008B; */
  color: red;
  margin: 0 15px;
  font-size: 4em;
`;

export const Itemimg = styled.img`
    height:100%;
`;

export const Span1 = styled.div`
    /* line-height: 20px; */
`;
export const Span2 = styled.div`
    /* line-height: 20px; */

`;
export const Span3 = styled.div`

`;