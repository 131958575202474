import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import { 
    FaFacebook,
    FaYoutube,
    // FaInstagram 
} from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    SocialMedia,
    SocialLogo,
    SocialMediaWrap,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
} from '../../components/Footer/FooterElements';


const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop()
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Mus galite rasti:</FooterLinkTitle>
                            <SocialIcons>
                                {/* <SocialIconLink href="/" target="_blank" area-label= "Instagram">
                                    <FaInstagram />
                                </SocialIconLink> */}
                                <SocialIconLink href="https://www.facebook.com/VGKrastovaizdzioArchitekturosStudija" target="_blank" area-label= "Facebook">
                                    <FaFacebook />
                                </SocialIconLink>
                                <SocialIconLink href="//www.youtube.com/channel/UCplQuFH8G6lOwUfdOkRx61g" target="_blank" area-label= "Youtube">
                                    <FaYoutube />
                                </SocialIconLink>
                            </SocialIcons>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            V&G Kraštovaizdžio architektūros studija
                        </SocialLogo>
                        <WebsiteRights>Visos teisės saugomos © {new Date().getFullYear()}</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
