import React, {useEffect, useState} from 'react';
import { FaBars } from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import {animateScroll as scroll} from 'react-scroll';
import Logo from '../../images/Logo.png';
import {
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    IMG,

} from './NavbarElements';

const Navbar = ({toggle}) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    },  [])
    
    const toggleHome = () => {
        scroll.scrollToTop()
    };

    return (
        <>
            <IconContext.Provider value={{color:'#fff'}}>    
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        <NavLogo to='/' onClick={toggleHome}>
                            <IMG src = {Logo} />
                            V&G
                        </NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks 
                                to='home'
                                smooth={true} 
                                duration={2000} 
                                spy={true} 
                                exact ='true' 
                                offset={-80}
                                // activeClass="active"
                                >Pradžia</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks 
                                to='about'
                                smooth={true} 
                                duration={2000} 
                                spy={true} 
                                exact ='true' 
                                offset={-80}
                                // activeClass="active"
                                >Apie mus</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks 
                                to='services'
                                smooth={true} 
                                duration={2000} 
                                spy={true} 
                                exact ='true' 
                                offset={-80}
                                // activeClass="active"
                                >Paslaugos</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks 
                                to='projects'
                                smooth={true} 
                                duration={2000} 
                                spy={true} 
                                exact ='true' 
                                offset={-80}
                                // activeClass="active"
                                >Projektai</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks 
                                to='publications'
                                smooth={true} 
                                duration={2000} 
                                spy={true} 
                                exact ='true' 
                                offset={-80}
                                // activeClass="active"
                                >Publikacijos</NavLinks>
                            </NavItem>
                            {/* <NavItem>
                                <NavLinks 
                                to='prices'
                                smooth={true} 
                                duration={2000} 
                                spy={true} 
                                exact ='true' 
                                offset={-80}
                                // activeClass="active"
                                >Kainos</NavLinks>
                            </NavItem> */}
                            <NavItem>
                                <NavLinks 
                                to='contacts'
                                smooth={true} 
                                duration={2000} 
                                spy={true} 
                                exact ='true' 
                                offset={-80}
                                // activeClass="active"
                                >Kontaktai</NavLinks>
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    );
};

export default Navbar
