import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import {Animated} from "react-animated-css";

function Menu({ menuItem }) {
    return (
      <div className="item">
        {menuItem.map(item => <MenuItem item={item} />)}
      </div>
    );
  }
  
  function MenuItem({ item }) {
    const [toggler, setToggler] = useState(false);
  
    return (
      <div >
        <div className="item-con" key={item.id}>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1800} animationOutDuration={2400} isVisible={true}>
            <div className="item-container">
              <img src={item.image} alt="" />
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              <button className="btn2" onClick={() => setToggler(!toggler)}> Daugiau</button>
            </div>
          </Animated>
        </div>
        <div>
          <FsLightbox toggler={toggler} sources={item.projectimages} />
        </div>
      </div>
    );
  }

export default Menu;

