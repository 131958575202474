import React, { useState } from 'react';

import Menu from './Menu';
import Button from './Button';
import data from './Data';
import './style.css';

const allCategories = ['Visi', ...new Set(data.map(item => item.category))];


const Projects = () => {


    const [menuItem, setMenuItem] = useState(data);
  const [buttons, SetButtons] = useState(allCategories);
  // console.log(data);

  const filter = (button) => {
    if(button === 'Visi'){
      setMenuItem(data);
      return;
    }
    const filteredData = data.filter(item => item.category === button);
    setMenuItem(filteredData);
  }

    return (
        <div id="projects" className="main-container">
        <div className="title">
            Projektai
        </div>
        <Button button={buttons} filter={filter}/>
        <Menu menuItem={menuItem}/>
      </div>
    )
}

export default Projects
