import React from 'react'
import {
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarLink,
    SidebarMenu

} from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>  
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='home' onClick={toggle}>Pradžia</SidebarLink>
                    <SidebarLink to='about' onClick={toggle}>Apie mus</SidebarLink>
                    <SidebarLink to='services' onClick={toggle}>Paslaugos</SidebarLink>
                    <SidebarLink to='projects' onClick={toggle}>Projektai</SidebarLink>
                    <SidebarLink to='publications' onClick={toggle}>Publikacijos</SidebarLink>
                    {/* <SidebarLink to='prices' onClick={toggle}>Kainos</SidebarLink> */}
                    <SidebarLink to='contacts' onClick={toggle}>Kontaktai</SidebarLink>
                </SidebarMenu>   
            </SidebarWrapper>          
        </SidebarContainer>
    )
}

export default Sidebar
