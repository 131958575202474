export const servicesSteps = {
    id: 'services',
    name: 'Kraštovaizdžio projektavimo procesas:',

    spanName1: '1 žingsnis: Konsultacija',
    span1: ' Aplinkos projektavimas prasideda nuo pirminės konsultacijos. Šio pirmo susitikimo metu klientai pateikia savo viziją, norus, aptariame galimą aplinkos stilistiką, pavyzdžius, analogus.',

    spanName2: '2 žingsnis: Koncepcija',
    span2: ' Parengiama koncepcija, kuriame atsispindi projekto vizija, teritorijos išplanavimas su takeliais, augmenijos vieta, bei mažoji architektūra.',
    
    spanName3: '3 žingsnis: Projektas',
    span3: ' Projekto pateikimas. Šiame etape yra rengiamas detalus kraštovaizdžio projektas, apimantis visus detalius brėžinius, aiškinamasis raštas, vizualizacijos, virtualus sodo turas.',
    
    spanName4: '4 žingsnis: Įgyvendinimas',
    span4: ' Projekto įgyvendinimas. Po projekto pateikimo galime imtis įgyvendinimo darbų. Šiame etape sudarome medžiagų ir įrengimo darbų sąmatą, apskaičiuojame kainas.',
};

