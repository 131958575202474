import styled from 'styled-components';

export const ContactContainer = styled.div`
    background-repeat:no-repeat;
    background-size: 100%;
    background-position: center;

    @media screen and (max-width: 768px){
        padding: 100px 0;
        background-position: bottom;

    }
`;

export const ContactWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 800px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const ContactRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const TextWrapper = styled.div`
    max-width: 768px;
    height: 50%;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const Conta= styled.div`
    height: 100%;
    margin-top:25px;
`;
export const Span = styled.div`
    font-size: 20px;
    text-align: center;
`;

export const Column2 = styled.div`
    margin-bottom: 50px;
    padding: 0 15px;
    grid-area: col2;
`;

export const ContactForm = styled.div`
    max-width: 768px;
    height: 50%;
`;

export const H1 = styled.h1`
    padding-bottom: 10px;
    width: 200px;
    color: #151416;
    border-bottom: 3px solid #151416;
`;

export const Form = styled.form`
    position: relative;
    width: 100%;
    height: 440px;
    border-radius: 8px;
    max-width: calc(100vw - 40px);
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
`;

export const Button = styled.button`
    position: absolute;
    right: 20px;
    bottom: 0;
    padding:10px 40px;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #151416;
    background: 0;
    color: #151416;
    cursor: pointer;
    transition: all 0.3s;

    :hover {
        background: #78788c;
        color: #fff;
    }
`;

export const ContactFill = styled.div`
    bottom: -15px;
    right: -20px;
    color: black;
    width: auto;
    /* padding: 16px 4px 16px 0; */
    font-size: 16px;
`;

export const ContactLabel = styled.label`
    ::before{
        content: attr(type);
        display: block;
        margin: 28px 0 0;
        font-size: 14px;
        color: #151416;
    }
`;


export const ContactInput = styled.input`
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s;
    border-bottom: 2px solid #151416;

    :focus {
        border-bottom: 2px solid #78788c;
    }
`;

export const TextArea = styled.textarea`
    padding-top:10px; 
    width: 100%;
    resize: none;
    border: none;
    background: none;
    border-bottom: 2px solid #151416;
    outline: none;
`;