import React, { useState } from 'react';
import Video from '../../videos/video-2.mp4';
import { Button } from '../ButtonElements';
import {
    HeroContainer, 
    HeroBg, 
    VideoBg, 
    HeroContent, 
    HeroH1, 
    HeroP, 
    HeroBtnWrapper, 
    ArrowForward, 
    ArrowRight
} from './HeroElements'

const HeroSection = () => {

    const [hover, setHover] = useState(false)

    const onHover = () => {

        setHover(!hover)
    }

    return (
        <HeroContainer id = 'home'>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type ='video-2/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1> V&G Kraštovaizdžio architektūros studija</HeroH1>
                <HeroP>Profesionali pagalba kuriant jaukią aplinką</HeroP>
                <HeroBtnWrapper>
                    <Button to='about' onMouseEnter={onHover} onMouseLeave={onHover}
                    primary ="true"
                    dark = "true"
                    smooth={true} 
                    duration={2000} 
                    spy={true} 
                    exact ='true' 
                    offset={-80}
                    >Apie mus {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
