import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import Fade from 'react-reveal/Fade';
import { BsDot } from 'react-icons/bs';
import { FiZoomIn } from 'react-icons/fi';
import img1 from '../../images/serviceimages/1.jpg';
import img2 from '../../images/serviceimages/2.jpg';
import img3 from '../../images/serviceimages/3.jpg';
import img4 from '../../images/serviceimages/4.jpg';
import img5 from '../../images/serviceimages/5.jpg';
import img6 from '../../images/serviceimages/6.jpg';
import img7 from '../../images/serviceimages/7.jpg';
import img8 from '../../images/serviceimages/8.jpg';
import img9 from '../../images/serviceimages/9.jpg';
import img10 from '../../images/serviceimages/10.jpg';
import img11 from '../../images/serviceimages/11.jpg';
import img12 from '../../images/serviceimages/12.jpg';
import img13 from '../../images/serviceimages/13.jpg';
import img14 from '../../images/serviceimages/14.jpg';
import {
    ServicesContainer, 
    ServicesWrapper, 
    ServicesRow, 
    Column1, 
    Column2, 
    TextWrapper, 
    Heading, 
    Subtitle,
    TopLine,
    ImgWrap,
    Img,
    Span,
    Spantitle,
    ZoomBtn
} from './ServicesElements';

const ServicesSection = ({
    name,
    span1,
    span2,
    span3,
    span4,
    spanName1,
    spanName2,
    spanName3,
    spanName4,
}) => {
        
    const [toggler, setToggler] = useState(false);

    return (
        <>
            <ServicesContainer  id="services">
                <ServicesWrapper>
                    <ServicesRow >
                        <Column1>
                            <TextWrapper>
                                <Fade  delay={700} duration={3000}>
                                    <Heading>Paslaugos</Heading>
                                </Fade>
                                <Subtitle >
                                    <Fade  delay={1000} duration={4000}>
                                        <Spantitle>{name}</Spantitle>
                                           <b>{spanName1}</b>{span1}
                                           <b>{spanName2}</b>{span2}
                                           <b>{spanName3}</b>{span3}
                                           <b>{spanName4}</b>{span4}
                                    </Fade>
                                </Subtitle>
                                <TopLine>
                                    <Fade delay={1300} duration={4000}>
                                        <Spantitle style={{paddingBottom: 15}}>
                                            Kraštovaizdžio sutvarkymo projektą sudaro šios projektinės dalys :
                                        </Spantitle>
                                    </Fade>
                                    <Fade  delay={1800} duration={4000}>
                                        <Span><BsDot/>Konsultacijos aplinkos dizaino ir įrengimo klausimais</Span>
                                    </Fade>
                                    <Fade  delay={2300} duration={4000}>
                                        <Span><BsDot/>Sklypo erdvių išplanavimo koncepcija</Span>
                                    </Fade>
                                    <Fade  delay={2800} duration={4000}>
                                        <Span><BsDot/>Daugiamečių augalų projektavimas</Span>
                                    </Fade>
                                    <Fade  delay={3300} duration={4000}>
                                        <Span><BsDot/>Trinkelių grindinių projektavimas</Span>
                                    </Fade>
                                    <Fade  delay={3800} duration={4000}>
                                        <Span><BsDot/>Lauko apšvietimo projektavimas</Span>
                                    </Fade>
                                    <Fade  delay={4300} duration={4000}>
                                        <Span><BsDot/>Vandens telkinių projektavmas</Span>
                                    </Fade>
                                    <Fade  delay={4800} duration={4000}>
                                        <Span><BsDot/>Lauko baldų, laužavietės projektavimas</Span>
                                    </Fade>
                                    <Fade  delay={5300} duration={4000}>
                                        <Span><BsDot/>Sklypo reljefo formavimas</Span>
                                    </Fade>
                                    <Fade  delay={5800} duration={4000}>
                                        <Span><BsDot/>Sklypo įrengimo darbų organizavimas <br/>ir autorinė priežiūra</Span>
                                    </Fade>
                                    <Fade  delay={6300} duration={4000}>
                                        <Span><BsDot/>Vizualizacijų rengimas</Span>
                                    </Fade>
                                </TopLine>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                        <ImgWrap>
                            <ZoomBtn>
                                <Fade duration={4200}>
                                <FiZoomIn onClick={() => setToggler(!toggler)}/>
                                </Fade>
                            </ZoomBtn>
                            <Fade duration={4000}>
                                <Img src={img1} alt=""/>
                            </Fade >
                                    <FsLightbox
                                        toggler={toggler}
                                        sources={[
                                            img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14
                                        ]}/>
                        </ImgWrap>
                        </Column2>
                    </ServicesRow>
                </ServicesWrapper>     
            </ServicesContainer>   
        </>
    )
}

export default ServicesSection
