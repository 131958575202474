import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import MessengerCustomerChat from 'react-messenger-customer-chat';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
      </Switch>
      <MessengerCustomerChat
          pageId="105688748769401" appId="470586697747129"
      />
    </Router>
  );
}

export default App;
