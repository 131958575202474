import styled from "styled-components";
import backgroundphoto from '../../images/background.jpg'

export const PricesContainer = styled.div`
    background-image: url(${backgroundphoto});
`;
export const Column1 = styled.div`
    margin-bottom: 15px;
    // padding: 0 100px;
    grid-area: col1;
    justify-content: center;

`;
export const Column2 = styled.div`
    margin-bottom: 50px;
    padding: 0 15px;
    grid-area: col2;
    justify-content: center;

`;
export const PricesForm = styled.div`
    max-width: 768px;
    height: 50%;
`;
export const PricesWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 0;
        height: auto;
    }
    @media screen and (max-width: 640px){
        padding: 0;
        height: auto;

    }
    @media screen and (max-width: 480px) {
        padding: 0 24px;
        height: auto;
        width:
    }
`;

export const PricesRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const Title = styled.h1`
    padding: 15px 0;
    height: 50px;
    text-align: center;
    color: black;
    // position: absolute;
    top:10px;
    margin-top: 25px;
    font-family: "Encode Sans Expanded", sans-serif;
    font-size: 40px;
    font-weight: 700;

    @media screen and (max-width: 640px){
        padding: 0;
        margin-top: 150px;
        font-size: 25px;
    }
    @media screen and (max-width: 768px){
        padding: 0;
    }
    
`
export const Taitlas = styled.h1`
    padding: 25px 0;
    text-align: center;
    color: black;
    // position: absolute;
    top:10px;
    margin-top: 25px;
    font-family: "Encode Sans Expanded", sans-serif;
    font-size: 20px;
    font-weight: 700;
`

export const H2 = styled.h2`
    font-size : 30px;
    padding: 20px;
    text-decoration: underline;

    @media screen and (max-width: 640px){
        font-size: 21px;
    }
    @media screen and (max-width: 460px){
        font-size: 18px;
    }
    `
    
    export const Tekstas =styled.h2`
    font-size: 19px;
    
    @media screen and (max-width: 640px){
        font-size: 20px;
    }
    @media screen and (max-width: 460px){
        font-size: 18px;
    }
    `