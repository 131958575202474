import React, {useState} from 'react';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import Services from '../components/Services';
import { servicesSteps } from '../components/Services/Data';
import Sidebar from '../components/Sidebar';
import Projects from '../components/Projects';
import Publications from '../components/Publications';
import Contacts from '../components/Contacts';

import { CartProvider } from 'react-use-cart';
// import PricesHome from '../components/Prices/PricesHome';
import Cart from '../components/Prices/Cart';

const Home = () => {
    
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {

        setIsOpen(!isOpen)
    }

    
    
    return (
        <>
        <Sidebar isOpen= {isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/> 
        <HeroSection />
        <InfoSection {...homeObjOne}/>
        <Services {...servicesSteps} />
        <Projects />
        <Publications />
        <CartProvider>
            {/* <PricesHome /> */}
            {/* <Cart /> */}
        </CartProvider>
        <Contacts /> 
        <Footer />
        
        </>
    )
}

export default Home
